<template>
  <v-navigation-drawer
    v-if="auth"
    id="core-navigation-drawer"
    v-bind="$attrs"
    ref="drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-breakpoint="0"
    app
    hide-overlay
    :width="dwidth || navigation.width"
  >
    <template #img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

    <v-divider class="mb-1" />

    <v-list
      dense
      nav
      disabled
    >
      <v-list-item>
        <v-list-item-avatar
          class="align-self-center"
          color="white"
          contain
        >
          <!--          <v-img-->
          <!--            src="https://demos.creative-tim.com/vuetify-material-dashboard/favicon.ico"-->
          <!--            max-height="30"-->
          <!--          />-->
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title
            class="text-h4"
            v-text="profile.title"
          />
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list>
      <v-list-item-content>
        <v-treeview
          v-if="nodes"
          ref="treemenu"
          v-model="tree"
          :items="nodes"
          :activatable="true"
          :load-children="loadPath"
          item-key="id"
          :open-on-click="true"
          transition
          :open="[1]"
          dense
        >
          <template
            slot="label"
            slot-scope="props"
          >
            <router-link
              v-if="props.item.path"
              :to="'/nodes' + props.item.path"
            >
              <span
                :data-is-open="`${!!props.item.open}`"
              >{{ props.item.name }}</span>
            </router-link>
            <span
              v-else
              :data-is-open="`${!!props.item.open}`"
              :data-id="`i-${props.item.id}`"
              :data-slug="`s-${props.item.slug}`"
            >{{ props.item.name }}</span>
          </template>
          <template #append="{ item }">
            <span style="z-index: 9999">
              {{ item.childcount }}
              <v-menu
                offset-y
                :close-on-content-click="false"
              >
                <template #activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ 'mdi-dots-vertical' }}
                  </v-icon>
                </template>
                <v-list class="py-0">
                  <v-list-item
                    class="pa-0 px-1"
                  >
                    <v-dialog
                      v-model="dialog[item.id]"
                      width="500"
                    >
                      <template #activator="{ on, attrs }">
                        <v-btn
                          width="100%"
                          small
                          v-bind="attrs"
                          v-on="on"
                          @click="fillFields(item)"
                        >
                          <v-icon small>
                            {{ 'mdi-pencil' }}
                          </v-icon>
                          Edit {{ item.name }}
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title class="text-h5">
                          Details of {{ item.name }}
                        </v-card-title>
                        <v-card-text>
                          <v-text-field
                            v-model="newNode.name"
                            :value="item.name || ''"
                          />
                          <v-text-field
                            v-model="newNode.description"
                          />
                          <v-btn
                            color="warning"
                            @click="updateNode(item.path)"
                          >Update</v-btn>
                          <pre> {{ item }}</pre>
                        </v-card-text>

                        <v-divider />

                        <v-card-actions>
                          <v-spacer />
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="dialog[item.id] = false"
                          >Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-list-item>
                  <v-list-item
                    link
                    class="pa-0 px-1"
                    dense
                  >
                    <v-dialog
                      v-model="dialogCreate[item.id]"
                      width="500"
                    >
                      <template #activator="{ on, attrs }">
                        <v-btn
                          color="success"
                          v-bind="attrs"
                          width="100%"
                          small
                          v-on="on"
                        >
                          <v-icon small>
                            {{ 'mdi-plus' }}
                          </v-icon>
                          Create new
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title class="text-h5">
                          Create new folder
                        </v-card-title>
                        <v-card-text>
                          Create new child of <cite>{{ item.path }}</cite>
                          <v-text-field
                            v-model="newNode.name"
                            label="Title"
                            :value="item.name || ''"
                          />
                          <v-text-field
                            v-model="newNode.description"
                            label="Description"
                          />
                        </v-card-text>
                        <v-divider />
                        <v-card-actions>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="dialogCreate[item.id] = false"
                          >Close
                          </v-btn>
                          <v-spacer />
                          <v-btn
                            color="success"
                            @click="createNode(item.path)"
                          >Submit</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-list-item>
                  <v-list-item
                    class="pa-0 px-1"
                    dense
                  >
                    <v-dialog
                      v-model="dialogDelete[item.id]"
                      width="500"
                    >
                      <template #activator="{ on, attrs }">
                        <v-btn
                          color="error"
                          v-bind="attrs"
                          width="100%"
                          small
                          v-on="on"
                        >
                          <v-icon small>
                            {{ 'mdi-delete' }}
                          </v-icon>
                          Delete
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title class="text-h5">
                          Deletion of {{ item.name }}
                        </v-card-title>
                        <v-card-text>
                          Are you sure to delete <cite>{{ newNode.name }}</cite> as children of <cite>{{ item.path + '/' }}</cite>?
                        </v-card-text>

                        <v-divider />

                        <v-card-actions>
                          <v-btn
                            color="error"
                            @click="deleteNode(item.path)"
                          >
                            <v-icon small>
                              {{ 'mdi-delete' }}
                            </v-icon>
                            Yes, delete
                          </v-btn>
                          <v-spacer />
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="dialogDelete[item.id] = false"
                          >No, close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-list-item>
                </v-list>
              </v-menu>
            </span>
          </template>
          <template #prepend="{ item, open }">
            <v-icon
              v-if="!item.file"
              :data-slug="`s-${item.slug}`"
            >
              {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
            </v-icon>
            <v-icon v-else>
              {{ files[item.file] }}
            </v-icon>
          </template>
        </v-treeview>
      </v-list-item-content>
    </v-list>

    <v-divider class="mb-2" />

    <v-list
      expand
      nav
    >
      <base-item
        :item="{ icon: 'mdi-magnify', title: 'Search', to: '/search-table', open: false}"
      />
    </v-list>

    <v-list
      expand
      nav
    >
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->

      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        />

        <base-item
          v-else
          :key="`item-${i}`"
          :item="item"
        />
      </template>
      <base-item
        :item="{ icon: 'mdi-logout', title: 'Logout', to: '/logout', open: false}"
      />
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
    </v-list>
    <!--    <template #append>-->
    <!--      <pre style="max-height: 200px; overflow-y: auto; background-color: white">&lt;!&ndash;-->
    <!--         &ndash;&gt;{{ $store.state.debug }}-->
    <!--      </pre>-->
    <!--    </template>-->
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import {
    mapState,
  } from 'vuex'

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      treeOpen: [1, 5],
      dialog: {},
      dialogCreate: {},
      dialogDelete: {},
      currentNode: {},
      currentPath: {},
      dwidth: undefined,
      isDark: false,
      newNode: {
        name: '',
        description: '',
      },
      files: {
        html: 'mdi-language-html5',
        js: 'mdi-nodejs',
        json: 'mdi-code-json',
        md: 'mdi-language-markdown',
        pdf: 'mdi-file-pdf',
        png: 'mdi-file-image',
        txt: 'mdi-file-document-outline',
        xls: 'mdi-file-excel',
      },
      tree: [],
      items: [
        {
          icon: 'mdi-view-dashboard',
          title: 'dashboard',
          to: '/',
        },
        {
          icon: 'mdi-account',
          title: 'user',
          to: '/pages/user',
        },
      ],
      open: [1],
      startLevel: 2,
      endLevel: 0,
      users: [],
      lapsed: false,
      active: [],
      currentBotClicked: undefined,
      navigation: {
        shown: false,
        width: sessionStorage.getItem('dwith') || 260,
        borderSize: 3,
      },
    }),
    created () {
      this.dwidth = sessionStorage.getItem('dwidth') || undefined
    },
    computed: {
      direction () {
        return this.drawer === false ? 'Open' : 'Closed'
      },
      ...mapState(['barColor', 'barImage', 'nodes', 'auth']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      current: {
        get () {
          return this.$store.state.nodes
        },
        set (val) {
          this.$store.commit('SET_CURRENT', val)
        },
      },
      computedItems () {
        return this.items.map(this.mapItem)
      },
      profile () {
        return {
          avatar: true,
          title: this.$t('avatar'),
        }
      },
    },
    watch: {
      mapItem (n) {
        console.log(n)
      },
    },
    mounted () {
      this.lapseMenu()
      this.setBorderWidth()
      this.setEvents()
    },
    methods: {
      setBorderWidth (w) {
        const i = this.$refs.drawer.$el.querySelector(
          '.v-navigation-drawer__border',
        )
        i.style.width = w || this.navigation.borderSize + 'px'
        i.style.cursor = 'ew-resize'
        i.style.backgroundColor = 'red'
      },
      setEvents () {
        const minSize = this.navigation.borderSize
        const el = this.$refs.drawer.$el
        const drawerBorder = el.querySelector('.v-navigation-drawer__border')
        const direction = el.classList.contains('v-navigation-drawer--right')
          ? 'right'
          : 'left'

        function resize (e) {
          document.body.style.cursor = 'ew-resize'
          const f =
            direction === 'right'
              ? document.body.scrollWidth - e.clientX
              : e.clientX
          el.style.width = f + 'px'
        }

        drawerBorder.addEventListener(
          'mousedown',
          (e) => {
            if (e.offsetX < minSize) {
              el.style.transition = 'initial'
              document.addEventListener('mousemove', resize, false)
            }
          },
          false,
        )

        document.addEventListener(
          'mouseup',
          () => {
            el.style.transition = ''
            document.removeEventListener('mousemove', resize, false)
            sessionStorage.setItem('dwidth', el.style.width.replace('px', ''))
            document.body.style.cursor = ''
            document.querySelector('.v-main').style.paddingLeft = el.style.width
            document.querySelector('header.v-app-bar').style.left = el.style.width
          },
          false,
        )
      },
      lapseMenu () {
        if (this.$refs.treemenu && this.$refs.treemenu.$el) {
          const slugParts = this.$route.path.split('/').filter(e => e !== '')
          const slugElems = []
          if (this.startLevel > this.endLevel) {
            this.endLevel = slugParts.length
          }
          if (this.endLevel >= this.startLevel) {
            setTimeout(async () => {
              if ([...slugParts][this.startLevel]) {
                this.currentBotClicked = String([...slugParts][this.startLevel])
                slugParts.forEach(e => {
                  if (this.$refs.treemenu.$el.querySelectorAll(`[data-slug=s-${e}]`).length > 0) {
                    slugElems.push(...this.$refs.treemenu.$el.querySelectorAll(`[data-slug=s-${[...slugParts][this.startLevel]}]`))
                  }
                })
              }
              if (slugElems[0]) {
                const wi = this.$refs.treemenu.$el.offsetWidth
                slugElems[0].click()
                await this.lapseMenu()
                this.startLevel += 1
                if (wi > 200) {
                  // this.navigation.width = wi
                }
              }
            }, 500)
          }
        }
      },
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
          open: true,
        }
      },
      fillFields (i) {
        this.newNode.name = i.name || ''
        this.newNode.description = i.description || ''
      },
      slugify (e) {
        if (!e) return
        return e.toLowerCase() // LowerCase
          .replace(/\s+/g, '-') // space to -
          .replace(/&/g, '-and-') // & to and
          .replace(/--/g, '-') || 'none'
      },
      async updateNode (path, isNew = false) {
        console.log(this.currentBotClicked, 'botty')
        const p = isNew ? path.replace(/([^/]+$)/, this.slugify(this.newNode.name)) : path
        await this.$store.dispatch('updateNode', { path: p, payload: this.newNode }).finally(() => {
          this.dialog = {}
        })
      },
      async deleteNode (path) {
        await this.$store.dispatch('deleteNode', { path }).finally(() => {
          this.dialogDelete = {}
        })
      },
      async createNode (path) {
        const payload = {
          ...this.newNode,
          slug: this.newNode.name.toLowerCase() // LowerCase
            .replace(/\s+/g, '-') // space to -
            .replace(/&/g, '-and-') // & to and
            .replace(/--/g, '-') || 'none',
        }
        await this.$store.dispatch('createNode', { path, payload }).finally(() => {
          this.dialogCreate = {}
        })
      },
      async loadPath (item) {
        if (item.path) {
          const apiPath = `https://api.hgkforms.campusderkuenste.ch/nodes${item.path}`
          await fetch(apiPath, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json; charset=utf-8',
              Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzM4NCJ9.eyJpc3MiOiJhdXRoLmhnay5maG53LmNoXC9oZ2tmb3JtcyIsIm5iZiI6MTYxNjYwMzgzNiwiaWF0IjoxNjE2NjAzODM2LCJleHAiOjE3MTY2MDAyNDAsInVzZXJJZCI6IjEiLCJlbWFpbCI6ImdyYXppYW5vLnZvbmFsbG1lbkBmaG53LmNoIiwiZmlyc3ROYW1lIjoiR3Jhemlhbm8iLCJsYXN0TmFtZSI6InZvbiBBbGxtZW4iLCJob21lT3JnIjoiZmhudy5jaCIsImdyb3VwcyI6ImZobndcL2hnaztmaG53XC9zdGFmZjtmaG53XC91c2VyO2dsb2JhbFwvZGlnbWE7Z2xvYmFsXC9ndWVzdDtnbG9iYWxcL3N0YWZmO2dsb2JhbFwvdXNlcjtoZ2tcL2JhO25ldFwvZmhudyJ9.660hLy9HxugAJyE2-aezY91NfZK2VZ6ByKo9IEaEhLeaV3wgNztF2bWsQGmBewYT',
            },
          }).then(res => res.json())
            .then(json => (
              json.children.forEach(e => {
                if (e.childcount > 0) {
                  item.children.push({
                    id: e.id,
                    name: e.metadata.name,
                    description: e.metadata.description,
                    path: e.fullpath,
                    slug: e.slug,
                    childcount: e.childcount,
                    children: [],
                  })
                } else {
                  item.children.push({
                    id: e.id,
                    description: e.metadata.description,
                    name: e.metadata.name,
                    slug: e.slug,
                    path: e.fullpath,
                  })
                }
              })
            ))
            .catch(err => alert(err))
        }
      },
    },
  }
</script>

<style lang="sass">
  @import '../../../../../node_modules/vuetify/src/styles/tools/rtl'
  .required
    label::after
      content: "*"
      color: red
  #core-navigation-drawer
    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
